<template>
	<div class="register">
		<Transition name="fade" appear>
			<h3>{{ translate('registerAccount') }}</h3>
		</Transition>

		<Transition name="fade-slide-up" appear>
			<form @submit.prevent="submit">
				<div class="form-input" :class="{ error: error.name }" :data-error="error.name">
					<label for="name">{{ translate('name') }}</label>
					<Icon name="person" width="18" />
					<input id="name" v-model="name" type="text" placeholder="John Doe" />
				</div>

				<div class="form-input" :class="{ error: error.username }" :data-error="error.username">
					<div class="label">
						<label for="username">{{ translate('username') }}</label>
						<span class="notice">{{ translate('mustBeUnique') }}</span>
					</div>
					<Icon name="at" width="18" />
					<input
						id="username"
						v-model="username"
						type="text"
						:class="[`state-${state.username}`]"
						placeholder="johndoe"
						@input="checkAvailability()" />
					<InputState :state="state.username" :show="!!state.username" />
				</div>

				<div class="form-input" :class="{ error: error.email }" :data-error="error.email">
					<label for="email">{{ translate('emailAddress') }}</label>
					<Icon name="email" width="18" />
					<input id="email" v-model="email" type="email" placeholder="john.doe@email.com" />
				</div>

				<div class="form-input" :class="{ error: error.password }" :data-error="error.password">
					<div class="label">
						<label for="password">{{ translate('password') }}</label>
						<span class="notice">{{ translate('passwordLength') }}</span>
					</div>
					<Icon name="lock" width="18" />
					<input id="password" v-model="password" type="password" placeholder="••••••••" />
				</div>

				<div class="checkbox">
					<input id="terms" v-model="agreeToTerms" type="checkbox" />
					<label for="terms">
						<span class="box">
							<Icon name="checkmark" width="14" />
						</span>
						<span class="text">
							{{ translate('registerAccount.agreeTerms') }}
							<span class="link" @click="showTerms = true">{{
								translate('registerAccount.agreeTerms.link')
							}}</span
							>.
						</span>
					</label>
				</div>

				<div v-if="error.other" class="error-panel">
					<Icon name="alert-triangle" class="red" width="18" />
					<p>{{ error.other }}</p>
				</div>

				<button
					type="submit"
					:class="['icon-right', { disabled: !agreeToTerms }]"
					:disabled="!agreeToTerms">
					{{ translate('registerAccount.register')
					}}<Icon name="arrow-forward" class="white" width="22" />
				</button>
				<p class="already-have-account">
					{{ translate('registerAccount.alreadyHaveAccount') }}
					<router-link
						:to="$route.query.redirect ? `/login?redirect=${$route.query.redirect}` : '/login'"
						>{{ translate('login') }}</router-link
					>
				</p>
			</form>
		</Transition>

		<Transition name="slide-up">
			<div v-if="showTerms" class="terms-dialog" @click.self="showTerms = false">
				<Icon class="close" name="close" fill="black" @click="showTerms = false" />
				<Terms />
			</div>
		</Transition>
	</div>
</template>

<script>
import { auth, db } from '@/lib/supabase'
import { debounce } from 'debounce'
import Terms from '@/views/Terms'

import InputState from '@/components/InputState.vue'
import Icon from '../components/Icon.vue'

export default {
	components: {
		InputState,
		Terms,
		Icon,
	},

	data() {
		return {
			name: '',
			username: '',
			email: '',
			password: '',
			agreeToTerms: false,
			showTerms: false,

			error: {
				name: null,
				username: null,
				email: null,
				password: null,
				other: null,
			},

			state: {
				username: null,
			},
		}
	},

	methods: {
		async submit() {
			this.error = {
				name: null,
				username: null,
				email: null,
				password: null,
				other: null,
			}

			if (!this.name || !this.username || !this.email || !this.password) {
				if (!this.name) this.error.name = this.translate('registerAccount.error.emptyName')
				if (!this.username)
					this.error.username = this.translate('registerAccount.error.emptyUsername')
				if (!this.email) this.error.email = this.translate('registerAccount.error.emptyEmail')
				if (!this.password)
					this.error.password = this.translate('registerAccount.error.emptyPassword')
				return
			}

			if (this.password.length < 6) {
				this.error.password = this.translate('registerAccount.error.passwordTooShort')
				return
			}

			if (this.state.username !== 'success') {
				return
			}

			const { user, session, error } = await auth.signUp(
				{
					email: this.email,
					password: this.password,
				},
				{
					data: {
						username: this.username,
						display_name: this.name,
						color:
							this.$store.state.colors[Math.floor(Math.random() * this.$store.state.colors.length)],
					},
				}
			)

			if (user && session) {
				if (this.$route.query.redirect) {
					this.$router.replace(this.$route.query.redirect)
				} else {
					this.$router.replace(`/profile/@${this.username}`)
				}
			} else {
				this.error.other = error.message
			}
		},

		checkAvailability() {
			if (!this.username) return

			this.error.username = null
			this.state.username = 'loading'

			this.debounce(async () => {
				if (!this.username) {
					this.state.username = null
					return
				}

				const { data: user, error } = await db
					.from('users')
					.select('*')
					.eq('username', this.username)
					.limit(1)

				if (error) {
					this.error.other = error.message
				} else {
					if (user.length === 0) {
						this.state.username = 'success'
					} else {
						this.error.username = this.translate('registerAccount.error.usernameAlreadyExists')
						this.state.username = 'error'
					}
				}
			})
		},

		debounce: debounce(function (fn) {
			fn()
		}, 1500),
	},
}
</script>

<style lang="scss" scoped>
.register {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	form {
		margin: 50px auto 0;
		max-width: 350px;
		width: 90%;
	}

	.form-input {
		position: relative;

		+ .form-input {
			margin-top: 25px;
		}

		input {
			padding-left: 30px;
		}
	}

	.checkbox {
		margin-top: 30px;
	}

	.error-panel {
		align-items: flex-start;
		background: rgba($red, 0.05);
		border: 2px solid rgba($red, 0.25);
		border-radius: $border-radius;
		color: $red;
		display: flex;
		font-size: 14px;
		font-weight: 600;
		margin-top: 25px;
		padding: 10px;
		text-align: left;

		.icon i {
			margin-right: 5px;
			top: 2px;
		}

		p {
			position: relative;
			top: 2px;
		}
	}

	button#forgot-password {
		opacity: 0.5;

		&:hover {
			opacity: 0.75;
		}

		&::before {
			content: '\2022';
			font-size: 10px;
			opacity: 0.5;
			margin: 0 5px;
		}
	}

	button[type='submit'] {
		justify-content: center;
		margin-top: 35px;
		width: 100%;
	}

	.already-have-account {
		margin-top: 10px;
	}

	.terms-dialog {
		background: white;
		bottom: 0;
		height: calc(100% - 75px);
		left: 0;
		overflow-y: auto;
		padding: 75px 0 125px;
		position: fixed;
		text-align: left;
		width: 100%;
		z-index: 10;

		.close {
			cursor: pointer;
			opacity: 0.75;
			position: fixed;
			right: 50px;
			top: 120px;
			transition: $transition;

			&:hover {
				opacity: 0.85;
			}
		}
	}
}
</style>
